import $ from "jquery";
import React, { Component } from "react";
import { Dropdown, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import admin_image from "../../assets/img/costic/customer-6.jpg";
import logo from "../../assets/img/kayana/main-logo/logo.svg";
import * as ProfileServiceAPI from "../../services/profile/ProfileServiceAPI";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  logout,
  setPropertyId,
  setUsername,
  setVendor,
  setFranchise,
  setFranchises,
} from "../../redux/user/userSlice";
import {
  setMenuNames,
  setItems,
  setCategoryNames,
  setSelectedMenu,
} from "../../redux/menu/menuSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import { isNotAuthorized } from "../../utils/functions";

class Topnavigation extends Component {
  state = {
    name: "",
    profile_image_url: admin_image,
    business_trading_name: "",
    url: "",
    is_master_franchise: false,
    is_multi_vendor: false,
    selectedFranchise: "",
    selectedVendor: "",
    franchises: [],
    vendors: [],
    externalURL: "https://help.kayana.co.uk/",
    openSelect: null, // Track which select is open
  };

  /*   handleResize = () => {
  
      this.setState({ WindowSize: window.innerWidth })
  
      if (this.state.WindowSize <= 1200) {
        setTimeout(this.addsidenavigation(), 2000);
  
      } else if ((this.state.WindowSize >= 1200)) {
        setTimeout(this.addsidenavigation(), 2000);
        //  window.removeEventListener('resize', this.handleResize)
      }
    } */

  /*  componentWillUnmount() {
     window.addEventListener("resize", null);
   }
  */

  componentDidMount = () => {
    localStorage.setItem("prop_id", this.props.propertyId);

    this.unlisten = this.props.history.listen((location, action) => {
      // Add your code here to execute when the URL changes
      //  location.hash && alert(location.hash)
      if (location.hash !== "#rep") {
        const screenWidth = window.innerWidth;
        if (screenWidth < 1000) {
          this.closeSideBar();
        }
      } else {
        location.hash = "";
      }
    });
    const { setCurrentUser } = this.props;
    const username = this.props.user.username
      ? this.props.user.username
      : localStorage.getItem("username");
    const business_trading_name = localStorage.getItem("business_trading_name");

    /*  window.addEventListener('resize', this.handleResize) */

    this.setState({
      username: username,
      business_trading_name: business_trading_name,
    });

    /* if (this.state.WindowSize <= 1400) {
      this.addsidenavigation();
    } */

    if (!(this.props.isFranchise || this.props.isVendor)) {
      this.fetchAccountInformation();
    } else if (this.props.mainPropertyId == this.props.propertyId) {
      this.fetchAccountInformation();
    }

    this.fetchFranchiseInformation();
    this.fetchVendorInformation();

    setTimeout(() => {
      if (
        isNotAuthorized(
          this.props?.location?.pathname,
          this.props?.user?.settings
        )
      ) {
        this.props.history.push({
          pathname: "/dashboard",
        });
      }
    }, 1000);

    ProfileServiceAPI.fetchProfile(username)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.setState({
              name: response.data.data.name,
              profile_image_url: response.data.data.profile_image_url,
            });
          }
        }
      })
      .catch((error) => {
        // throw new Error(error)
      });
  };

  componentWillUnmount() {
    // Clean up the listener when the component unmounts
    this.unlisten();
  }
  handleChange = (event) => {
    this.setState({ selectedFranchise: event.target.value });
    //   localStorage.setItem("property_id", event.target.value);
    this.props.setPropertyId(event.target.value);
    const result = this.state.franchises.find(
      (item) => item.property_id === event.target.value
    );
    localStorage.setItem("business_name", result?.property_name);

    this.props.setUsername(result?.username || this.props.user.user.username);
    localStorage.setItem(
      "username",
      result?.username || this.props.user.user.username
    );

    this.props.setSelectedMenu(null);
    this.props.setMenuNames(null);
    this.props.setCategoryNames(null);
    this.props.setItems(null);
    if (window.location.pathname === "/orderdetails") {
      this.props.history.push("/orders-delivery/delivery");
    } else if (window.location.pathname === "/payoutdetails") {
      this.props.history.push("/payout");
    } else if (window.location.pathname === "/pay") {
      this.props.history.push("/manage-qr-flow");
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  handleVendorChange = (event) => {
    this.setState({ selectedVendor: event.target.value });
    this.props.setPropertyId(event.target.value);
    const result = this.state.vendors.find(
      (item) => item.property_id === event.target.value
    );
    this.props.setUsername(result?.username || this.props.user.user.username);
    localStorage.setItem(
      "prop_id",
      result?.property_id || this.props.user.propertyId
    );

    localStorage.setItem(
      "username",
      result?.username || this.props.user.user.username
    );
    localStorage.setItem("business_name", result?.property_name);

    this.props.setUsername(result?.username);

    this.props.setSelectedMenu(null);
    this.props.setMenuNames(null);
    this.props.setCategoryNames(null);
    this.props.setItems(null);
    if (window.location.pathname === "/orderdetails") {
      this.props.history.push("/orders-delivery/delivery");
    } else if (window.location.pathname === "/payoutdetails") {
      this.props.history.push("/payout");
    } else if (window.location.pathname === "/pay") {
      this.props.history.push("/manage-qr-flow");
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  fetchAccountInformation() {
    const dump = {
      property_id: this.props.propertyId,
      username: localStorage.getItem("username"),
    };

    PaymentServiceAPI.fetchAccountStatus(dump)
      .then((response) => {
        if (response.data.status) {
          //     this.setState({ loading: false, show: false });
          //    toast.success("Message Sent Successfully");

          this.setState({
            is_master_franchise: response.data.data.is_master_franchise,
            is_multi_vendor: response.data.data.is_multi_vendor,
            isLoading: false,
          });
          this.props.setFranchise(response.data.data.is_master_franchise);
          this.props.setVendor(response.data.data.is_multi_vendor);

          //   this.setState({ summary: response[0].data.data });
        } else {
          //     toast.error(response.data.message);
          //     this.setState({ loading: false });
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  fetchFranchiseInformation() {
    const dump = {
      property_id: this.props.user.user.property_id,
    };

    PaymentServiceAPI.fetchFranchiseInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          //     this.setState({ loading: false, show: false });
          //    toast.success("Message Sent Successfully");
          this.setState({ franchises: response.data.data });
          this.props.setFranchises([
            ...response.data.data,
            {
              property_id: this.props.propertyId,
              property_name: this.props.user.user.business_trading_name,
            },
          ]);

          //   this.setState({ summary: response[0].data.data });
        } else {
          this.setState({ franchises: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ franchises: [] });
      });
  }

  fetchVendorInformation() {
    const dump = {
      property_id: this.props.user.user.property_id,
    };

    PaymentServiceAPI.fetchVendorInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          //     this.setState({ loading: false, show: false });
          //    toast.success("Message Sent Successfully");

          this.setState({ vendors: response.data.data });

          //   this.setState({ summary: response[0].data.data });
        } else {
          this.setState({ vendors: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ vendors: [] });
      });
  }

  signout = () => {
    localStorage.clear();
    localStorage.removeItem("activeNavItem");
    this.props.logout();
    this.props.history.push("/");

    // SecurityServiceAPI.signout()
    //   .then((response) => {
    //     if (response.data.status) {
    //       localStorage.clear();
    //       this.props.history.push("/login");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  addsidenavigation = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  closeSideBar = () => {
    $(".ms-body").removeClass("ms-aside-left-open");
    $("#ms-side-nav").removeClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").removeClass("d-block");
  };

  topbaropen = () => {
    $("#ms-nav-options").toggleClass("ms-slide-down");
  };

  subscribeNotification = () => {};

  handleOpen = (selectId) => {
    this.setState({ openSelect: selectId });
  };

  handleClose = () => {
    this.setState({ openSelect: null });
  };

  render() {
    const { externalURL } = this.state;
    return (
      <nav className="navbar ms-navbar">
        <div
          className="ms-aside-toggler ms-toggler pl-0"
          onClick={this.addsidenavigation}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
        <div className="logo-sn logo-sm ms-d-block-sm">
          <Link
            className="pl-0 ml-0 text-center navbar-brand mr-0"
            to="/dashboard"
          >
            <img src={logo} />{" "}
          </Link>
        </div>
        <ul id="ms-nav-options" className="ms-nav-list ms-inline mb-0">
          <li className="ms-nav-item dropdown">
            {this.props.isFranchise && (
              <FormControl fullWidth sx={{ width: "170px;", zIndex: 9999 }}>
                <InputLabel id="property_id">Select Franchise</InputLabel>
                <Select
                  labelId="property_id"
                  id="property_id"
                  name="property_id"
                  style={{ background: window.innerWidth < 1000 && "white" }}
                  value={this.state.selectedFranchise || this.props.propertyId}
                  onChange={this.handleChange}
                  onOpen={() => this.handleOpen("franchise")}
                  onClose={this.handleClose}
                  open={this.state.openSelect === "franchise"} // Open only if this select is active
                  input={
                    <OutlinedInput id="property_id" label="Select Franchise" />
                  }
                  defaultValue={
                    this.state.selectedFranchise || this.props.propertyId
                  }
                  className="formControl"
                >
                  <MenuItem
                    key={this.props.user.user.property_id}
                    value={this.props.user.user.property_id}
                  >
                    {this.props.user.user.business_trading_name}
                  </MenuItem>
                  {this.state.franchises &&
                    this.state.franchises.map((item) => {
                      return (
                        <MenuItem
                          key={item.property_id}
                          value={item.property_id}
                        >
                          {item.property_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </li>
          <li className="ms-nav-item dropdown">
            {this.props.isVendor && (
              <FormControl fullWidth sx={{ width: "170px;", zIndex: 9999 }}>
                <InputLabel id="property_id">Select Vendor</InputLabel>
                <Select
                  labelId="property_id"
                  id="property_id"
                  name="property_id"
                  style={{ background: window.innerWidth < 1000 && "white" }}
                  value={this.state.selectedVendor || this.props.propertyId}
                  open={this.state.openSelect === "vendor"}
                  onChange={this.handleVendorChange}
                  onOpen={() => this.handleOpen("vendor")}
                  onClose={this.handleClose}
                  input={
                    <OutlinedInput id="property_id" label="Select Vendor" />
                  }
                  defaultValue={
                    this.state.selectedVendor || this.props.propertyId
                  }
                  className="formControl"
                >
                  <MenuItem
                    key={this.props.user.user.property_id}
                    value={this.props.user.user.property_id}
                  >
                    {this.props.user.user.business_trading_name}
                  </MenuItem>
                  {this.state.vendors &&
                    this.state.vendors.map((item) => {
                      return (
                        <MenuItem
                          key={item.property_id}
                          value={item.property_id}
                        >
                          {item.property_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </li>
          <li
            className="ms-nav-item ms-nav-user dropdown"
            style={{ zIndex: 9999 }}
          >
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                {this.state.profile_image_url ? (
                  <img
                    className="ms-user-img ms-img-round"
                    src={this.state.profile_image_url}
                  />
                ) : (
                  <img className="ms-user-img ms-img-round" src={admin_image} />
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right user-dropdown"
                aria-labelledby="userDropdown"
              >
                <div className="dropdown-menu-header">
                  <h6 className="dropdown-header ms-inline m-0">
                    <span className="text-disabled">
                      {" "}
                      {this.state.name ? "Welcome, " + this.state.name : ""}
                    </span>
                  </h6>
                </div>
                <div className="dropdown-divider" />
                <div className="ms-dropdown-list">
                  <Link className="media fs-14 p-2" to="/user-profiles">
                    <span>
                      <i className="fa flaticon-user mr-2" />
                      User Profile
                    </span>
                  </Link>

                  <Link className="media fs-14 p-2" to="/setting">
                    <span>
                      <i className="fa flaticon-gear mr-2" /> Account Settings
                    </span>
                  </Link>
                  {/*   <a className="media fs-14 p-2" href={externalURL} target="_blank" rel="noopener noreferrer">
                    <span>
                      <i className="far fa-question-circle fa-lg mr-2" /> Help
                    </span>
                  </a> */}
                </div>
                <div className="dropdown-divider" />

                <div className="dropdown-menu-footer">
                  <Link
                    className="media fs-14 p-2"
                    to=""
                    onClick={this.signout}
                  >
                    {" "}
                    <span>
                      <i className="fa flaticon-shut-down mr-2" /> Logout
                    </span>
                  </Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="ms-nav-item">
            <a
              className="media fs-14 p-2"
              href={externalURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="far fa-question-circle fa-lg mr-2" /> Help
              </span>
            </a>
          </li>
        </ul>
        <div
          className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
          onClick={this.topbaropen}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  mainPropertyId: state.user.mainPropertyId,
  isFranchise: state.user.isFranchise,
  isVendor: state.user.isVendor,
});

// const mapDispatchToProps = (dispatch) => ({
// setCurrentUser: (user) => dispatch(setCurrentUser(user)),

// });

const mapDispatchToProps = {
  logout,
  setPropertyId,
  setUsername,
  setFranchise,
  setVendor,
  setMenuNames,
  setCategoryNames,
  setItems,
  setSelectedMenu,
  setFranchises,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Topnavigation))
);
